export const SET_BREAKPOINT = "SET_BREAKPOINT";

export const setBreakpoint = (breakpoint) => ({
  type: SET_BREAKPOINT,
  payload: breakpoint,
});

const initialState = "xs"; // 注意修正了拼写错误：从 intialState 到 initialState

const breakpointReducer = (state = initialState, action) => {
  const { type, payload } = action; // 解构action对象以获取type和payload

  switch (type) {
    case SET_BREAKPOINT:
      return payload;
    default:
      return state;
  }
};

export default breakpointReducer;